* {
  box-sizing: border-box;
}

html, body {
  --font-size-h6: 12px;
  --font-size: 14px;
  --font-size-h4: 16px;
  --font-size-h3: 20px;
  --font-size-h2: 24px;
  --font-size-h1: 28px;
  --font-size-heading: 20px;
  --font-size-subheading: 12px;
  --font-family: 'halyard-display', sans-serif;
  --font-family-subheading: 'Work Sans', sans-serif;
  --color-white: #fff;
  --color-black: #000;
  --color-black-1: #F0F4F8;
  --color-black-2: #D9E2EC;
  --color-black-3: #BCCCDC;
  --color-black-4: #9FB3C8;
  --color-black-5: #829AB1;
  --color-black-6: #627D98;
  --color-black-7: #486581;
  --color-black-8: #334E68;
  --color-black-9: #243B53;
  --color-black-10: #102A43;
  --color-primary-1: #FFFAEB;
  --color-primary-2: #FCEFC7;
  --color-primary-3: #F8E3A3;
  --color-primary-4: #F9DA8B;
  --color-primary-5: #F7D070;
  --color-primary-6: #E9B949;
  --color-primary-7: #C99A2E;
  --color-primary-8: #A27C1A;
  --color-primary-9: #7C5E10;
  --color-primary-10: #513C06;
  --color-primary: var(--color-primary-5);
  --color-red: #FF5959;
  --color-red-dark: #410000;
  --color-green: #00DC72;
  --color-green-dark: #003A1E;
  --color-blue: #1da1f2;
  --color-text: var(--color-black-10);
  --color-modal-overlay: rgba(0,0,0,0.7);
  --border-radius: 4px;
  --letter-spacing-subheading: 1px;
  --overlay-fade: linear-gradient(transparent, rgba(0,0,0,0.9));
  --overlay-fade-2: linear-gradient(180deg, transparent 70%, var(--color-black-1) 100%);
  --text-shadow-default: 0 1px 1px var(--color-black-1);
  --form-size-large: 50px;
  --form-size: 40px;
  --form-size-small: 32px;
  --zindex-modal: 1000;

  color: var(--color-text);
  font-size: var(--font-size);
  font-family: var(--font-family);
  background: var(--color-black-1);
}

h1 {
  font-size: var(--font-size-h1);
}

h2 {
  font-size: var(--font-size-h2);
}

h3 {
  font-size: var(--font-size-h3);
}

h4 {
  font-size: var(--font-size-h4);
}

h5 {
  font-size: var(--font-size);
}

h6 {
  font-size: var(--font-size-h6);
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
}

p, h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

.u-text-center {
  text-align: center;
}

[aria-label][data-balloon-pos] {
  cursor: inherit !important;
}