.toast-container {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 32px;
  right: 32px;
  pointer-events: none;
  z-index: 1000;
}

.toast-float {
  display: flex;
  flex-direction: column;
}

.toast-float > .item {
  position: relative;
  display: inline-block;
  padding: 12px;
  padding-right: 32px;
  font-size: 12px;
  color: var(--color-white);
  background: #292929;
  border: 1px solid var(--color-black-2);
  border-radius: var(--border-radius);
  box-shadow: 0 2px 8px rgba(0,0,0,0.15);
  pointer-events: all;
}

.toast-float > .item > .close {
  position: absolute;
  top: 12px;
  right: 12px;
  padding: 0;
  cursor: pointer;
  background: transparent;
  color: var(--color-white);
  border: 0;
  opacity: 0.4;
  outline: 0;
}

.toast-float > .item:not(:last-child) {
  margin-bottom: 16px;
}

.toast-float > .item.-enter {
  opacity: 0;
  transform: translateY(16px);
}

.toast-float > .item.-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: 0.3s all ease;
}

.toast-float > .item.-leave {
  opacity: 1;
  transform: translateY(0);
}

.toast-float > .item.-leave-active {
  opacity: 0;
  transform: translateY(-16px);
  transition: 0.3s all ease;
}